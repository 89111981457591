import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import { color, font } from "../../layout/global"

// import LeftArrow from "../../images/Products/left-arrow.svg"
// import RightArrow from "../../images/Products/right-arrow.svg"

const SliderBlog = ({ id, data }) => {
  const posts = data

  /*   const PrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <LeftArrow
        className={className}
        style={{
          ...style,
          position: "absolute",
          zIndex: "2",
          width: "10px",
          height: "20px",
          cursor: "pointer",
          fontSize: "15px",
          left: "0",
          bottom: "50%",
          margin: "0 0 50px 20px",
          fill: color.grey,
        }}
        onClick={onClick}
      />
    )
  }

  const NextArrow = props => {
    const { className, style, onClick } = props
    return (
      <RightArrow
        className={className}
        style={{
          ...style,
          position: "absolute",
          zIndex: "2",
          width: "10px",
          height: "20px",
          cursor: "pointer",
          fontSize: "15px",
          right: "0",
          bottom: "50%",
          margin: "0 20px 50px 0",
          fill: color.grey,
        }}
        onClick={onClick}
      />
    )
  } */

  const [state, setState] = useState({
    previousSlide: -1,
    activeSlide: 0,
    nextSlide: 1,
  })

  const sliderSettings = {
    dots: true,
    infinite: false,
    centerMode: true,
    centerPadding: "350px",
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: false,
    afterChange: current =>
      setState({
        activeSlide: current,
        nextSlide: current + 1,
        previousSlide: current - 1,
      }),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "15%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          centerMode: true,
          centerPadding: "15px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2600,
        settings: {
          centerMode: true,
          centerPadding: "15px",
          slidesToShow: 4,
        },
      },
    ],
  }

  return (
    <Wrapper
      id={id}
      previousSlide={state.previousSlide}
      activeSlide={state.activeSlide}
    >
      <Slider {...sliderSettings}>
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className="container" key={post.id}>
                <div className="image">
                  <Img
                    fluid={post.frontmatter.image.childImageSharp.fluid}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                  />
                </div>
                <Link to={post.frontmatter.path}>
                  <h1>{post.frontmatter.title}</h1>
                </Link>
              </div>
            )
          })}
      </Slider>
    </Wrapper>
  )
}

export default SliderBlog

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 75px 0 75px 0;
  display: block;
  margin-bottom: -1px;

  @media screen and (max-width: 500px) {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    padding: 125px 0 50px 0;
  }

  // FIX: Unable to preventDefault inside passive event listener
  .slick-list, .slick-track { touch-action: pan-y; }

  .slick-track {
    display: flex;
  }

  .slick-slider {
    margin: 100px 0 50px 0;
  }

  .slick-slide {
    margin: 0px 25px;
    transition: all 0.3s ease-out;
  }

  .slick-slide > div {
    height: 100%;
  }

  .image {
    opacity: 0.65;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .slick-slider {
      margin: 50px 0 0px 0;
    }
  }

  .container {
    position: relative;
    height: 100%;
    background: black;

    h1 {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 7%;
      color: ${color.white};
      text-transform: uppercase;
      ${font.robotoBold};
      letter-spacing: 3px;
      font-size: min(max(15px, 5vw), 25px);

      @media only screen and (min-width: 451px) and (max-width: 1920px) {
        font-size: calc(16px + 9 * (100vw - 450px) / 1470);
      }
      @media only screen and (min-width: 1024px) {
        font-size: 16px;
      }
      @media only screen and (min-width: 1920px) {
        font-size: 18px;
        letter-spacing: 1px;
      }
      @media only screen and (max-width: 900px) {
        font-size: 14px;
        letter-spacing: 1px;
        padding: 5%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

  .image {
    height: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
  .slick-slider {
    position: relative;

    .slick-list {
      overflow: hidden;
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      padding-top: 75px;

      @media only screen and (max-width: 1024px) {
        padding-top: 25px;
      }

      li {
        position: relative;
        margin: 0 5px;

        button {
          width: 45px;
          height: 4px;
          font-size: 0;
          color: transparent;
          border: 0;
          border-radius: 0;
          background: ${color.grey};
          cursor: pointer;

          @media only screen and (max-width: 900px) {
            width: 20px;
          }

          &:focus {
            outline: 0;
          }
        }

        &.slick-active button {
          background: ${color.red};
          cursor: pointer;
        }
      }
    }

    .slick-arrow {
      &:hover {
        fill: ${color.red} !important;
      }
    }

    /* .slick-prev {
      display: ${props =>
        props.previousSlide === -1 ? "none !important" : "block !important"};

      @media only screen and (max-width: 600px) {
        margin: 0 0 0 20px !important;
      }
    }

    .slick-next {
      display: ${props =>
        props.activeSlide === props.totalSlides
          ? "none !important"
          : "block !important"};

      @media only screen and (max-width: 600px) {
        margin: 0 20px 0 0 !important;
      }
    } */
  }
`
