import React from "react"
import styled from "styled-components"
import { color, font } from "../../layout/global"

const BlogTitle = ({ id, data }) => {
  return (
    <Wrapper id={id}>
      <div className="container">
        <h1 className="first">{data.title}</h1>
        <h1 className="second">{data.subtitle}</h1>
      </div>
    </Wrapper>
  )
}

export default BlogTitle

const Wrapper = styled.section`
  background: #fff;
  padding: 75px 0;
  margin-bottom: -1px;

  @media only screen and (max-width: 1440px) {
    padding: 0 0 0 0;
  }
  @media only screen and (max-width: 900px) {
    padding: 25px 0 25px 0;
  }

  @media only screen and (max-width: 500px) {
    padding: 75px 0 25px 0;
  }

  .container {
    padding: 100px 15%;
    background: ${color.greyAbout};

    @media only screen and (max-width: 900px) {
      padding: 50px 15%;
    }

    h1 {
      ${font.robotoBold};
      font-size: min(max(15px, 5vw), 20px);
      margin: 0;
      line-height: 1;
      text-transform: uppercase;
      line-height: 1.5;
    }

    .first {
      color: ${color.greyDark};
    }

    .second {
      color: ${color.grey};
    }
  }
`
