import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components" // eslint-disable-line
import Img from "gatsby-image"
import { color, font } from "../../layout/global"
import Slider from "react-slick"

import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

const GridBlog = ({ id, data }) => {
  const posts = data

  const PrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <LeftArrow
        className={className}
        style={{
          ...style,
          position: "sticky",
          zIndex: "2",
          width: "10px",
          height: "20px",
          cursor: "pointer",
          fontSize: "15px",
          top: "48.2%",
          marginRight: "auto",
          marginLeft: "10px",
          fill: color.grey,
        }}
        onClick={onClick}
      />
    )
  }

  const NextArrow = props => {
    const { className, style, onClick } = props
    return (
      <RightArrow
        className={className}
        style={{
          ...style,
          position: "sticky",
          zIndex: "2",
          width: "10px",
          height: "20px",
          cursor: "pointer",
          fontSize: "15px",
          bottom: "49.5%",
          marginLeft: "auto",
          marginRight: "10px",
          fill: color.grey,
        }}
        onClick={onClick}
      />
    )
  }

  const [state, setState] = useState({
    previousSlide: -1,
    activeSlide: 0,
    nextSlide: 1,
  })

  const [totalSlides, setTotalSlides] = useState(0)

  const sliderSettings = {
    dots: true,
    infinite: false,
    swipeToSlide: false,
    rows: 3,
    slidesPerRow: 3,
    swipe: false,
    nextArrow: <NextArrow role="button" />,
    prevArrow: <PrevArrow role="button" />,
    afterChange: current =>
      setState({
        activeSlide: current,
        nextSlide: current + 1,
        previousSlide: current - 1,
      }),
    customPaging: i => {
      setTotalSlides(i)
      return <button aria-label="arrow" />
    },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesPerRow: 1,
        },
      },
    ],
  }

  return (
    <Wrapper
      id={id}
      previousSlide={state.previousSlide}
      activeSlide={state.activeSlide}
      totalSlides={totalSlides}
    >
      <div className="container">
        <Slider {...sliderSettings}>
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <Link key={post.id} to={post.frontmatter.path}>
                  <div className="card-container">
                    <Img
                      fluid={post.frontmatter.image.childImageSharp.fluid}
                      imgStyle={{
                        objectFit: "cover",
                        objectPosition: "center center",
                      }}
                    />
                    <div className="text-container">
                      <span>{post.frontmatter.date}</span>
                      <h5>{post.frontmatter.title}</h5>
                    </div>
                  </div>
                </Link>
              )
            })}
        </Slider>
      </div>
    </Wrapper>
  )
}

export default GridBlog

const Wrapper = styled.section`
  background: #fff;
  padding: 0 0 50px 0;

  .container {
    max-width: 2000px;
    margin: 0 auto;

    .slick-slider {
      position: relative;

      .slick-list {
        overflow: hidden;
      }

      .slick-track {
        margin: 0 auto;
        display: flex;

        .slick-slide a:focus {
          outline: 0;
        }
      }

      .slick-dots {
        display: flex !important;
        justify-content: center;
        padding-top: 75px;

        @media only screen and (max-width: 1024px) {
          padding-top: 25px;
        }

        li {
          position: relative;
          margin: 0 5px;

          button {
            width: 45px;
            height: 4px;
            font-size: 0;
            color: transparent;
            border: 0;
            border-radius: 0;
            background: ${color.grey};
            cursor: pointer;

            @media only screen and (max-width: 900px) {
              width: 20px;
            }

            &:focus {
              outline: 0;
            }
          }

          &.slick-active button {
            background: ${color.red};
            cursor: pointer;
          }
        }
      }
    }

    .slick-arrow {
      &:hover {
        fill: ${color.red} !important;
      }
    }

    .slick-prev {
      display: ${props =>
        props.previousSlide === -1 ? "none !important" : "block !important"};
    }

    .slick-next {
      display: ${props =>
        props.activeSlide === props.totalSlides
          ? "none !important"
          : "block !important"};
    }

    .slick-slide > div {
      display: flex;
    }

    .card-container {
      margin: 50px 30px;
      position: relative;

      .gatsby-image-wrapper {
        max-height: 320px;
      }

      .text-container {
        margin-top: 15px;

        h5 {
          ${font.financierBold};
          color: ${color.red};
          font-size: 20px;
          margin: 0;
          line-height: 1;
        }

        span {
          text-transform: uppercase;
          color: ${color.greyDarker};
          ${font.robotoBold};
          font-size: 10px;
        }
      }
    }
  }
`
